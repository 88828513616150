import * as React from 'react';
import cn from 'classnames';
import css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import A from 'styleguide/components/Links/A';
import { FileConcern, ProofApproval } from './types';
import ProofApprovalFileConcern from 'bundles/App/pages/ProofApprovalsPage/ProofApprovalFileConcern';
import Button from 'styleguide/components/Button/Button';
import { Status } from 'libs/utils/api/types';
import { updateProofApproval } from 'api/proofApprovals';
import CustomerServiceContact from './CustomerServiceContact';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import Modal from 'styleguide/components/Modal/Modal';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { array, boolean, object, number, string } from 'yup';

import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';

export interface Props {
  orderNumber: string;
  proofApproval: ProofApproval;
  setProofApprovalState: (proofApproval: ProofApproval) => void;
}

const proofApprovalSchema = object().shape({
  proofApprovalFileConcernsAttributes: array().of(
    object<FileConcern>().shape({
      id: number().required(),
      approved: boolean()
        .required('Each order concern must be approved or rejected')
        .typeError('Each order concern must be approved or rejected'),
      customerResponse: string().nullable(),
      message: string(),
      detailedMessage: string().nullable(),
    }),
  ),
});

const ProofApprovalForm = ({ orderNumber, proofApproval, setProofApprovalState }: Props) => {
  const [showRejectModal, setShowRejectModal] = React.useState<boolean>();
  const [approved, setApproved] = React.useState<boolean>();

  const onSubmit = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    updateProofApproval(orderNumber, proofApproval.id, {
      proofApproval: {
        approved,
        proofApprovalFileConcernsAttributes: values.proofApprovalFileConcernsAttributes,
      },
    }).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        setProofApprovalState(res.payload.proofApproval);
      }
    });
  };

  const plural = proofApproval.uploads.length > 1 ? 's' : '';
  const bodyText =
    `Simply download your proof file${plural} by clicking on the link${plural} ` +
    `below and review your file concerns. ` +
    `If you'd like to make changes to your file${plural}, you will still able to resubmit. ` +
    `However, this may delay your delivery date.`;
  const fileViewWarning = `Do not view on a mobile or tablet device. This can lead to unexpected issues when viewing`;

  return (
    <Formik
      initialValues={{ proofApprovalFileConcernsAttributes: proofApproval.fileConcerns }}
      onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
        onSubmit(values, setStatus, setSubmitting, setErrors);
      }}
      validationSchema={proofApprovalSchema}
    >
      {formikProps => (
        <Form>
          {!!showRejectModal && (
            <Modal shown onClose={() => setShowRejectModal(false)}>
              <Grid.Container>
                <Grid>
                  <Grid.Row>
                    <Grid.Col className={cn(css.modalContainer)} sm={12}>
                      <div data-cy="rejectingProofWarningMessage" className={cn(css.modalHeading)}>
                        Rejecting your proof will delay your delivery date!
                      </div>
                      <div className={cn(css.buttonContainer)}>
                        <Button
                          dataCy="takeMeBackBtn"
                          className={cn(css.modalButton)}
                          type="button"
                          color="blue"
                          onClick={() => setShowRejectModal(false)}
                        >
                          Take me back!
                        </Button>
                        <Button
                          dataCy="rejectMyProofBtn"
                          className={cn(css.modalButton)}
                          type="button"
                          color="dark"
                          outline
                          onClick={() => {
                            setApproved(false);
                            formikProps.submitForm();
                            setShowRejectModal(false);
                          }}
                        >
                          Reject my proof
                        </Button>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Grid.Container>
            </Modal>
          )}
          <Grid.Row>
            <Grid.Col>
              <Span data-cy="proofApprovalPageTitle" className={cn(css.title, css.active)}>
                Proof Approval
              </Span>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <HtmlContent className={css.bodyText} content={bodyText} />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <CustomerServiceContact orderReservation={proofApproval.orderReservation} />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className={css.sectionSeparator}>
            <Grid.Col>
              <HtmlContent
                content={`Download your digital proof${plural}`}
                className={cn(css.sectionHeader, css.sectionBodySeparator)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              {proofApproval.uploads.map(file => (
                <>
                  <A href={file.url} key={file.name} color="blue" underline="always" targetBlank>
                    {file.name}
                  </A>
                  <br />
                </>
              ))}
              <HtmlContent className={css.warning} content={fileViewWarning} />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className={css.sectionSeparator}>
            <Grid.Col>
              <HtmlContent
                content="Review your order concerns"
                className={cn(css.sectionHeader, css.sectionBodySeparator)}
              />
            </Grid.Col>
          </Grid.Row>
          {formikProps.values.proofApprovalFileConcernsAttributes.map((fileConcern, i) => (
            <ProofApprovalFileConcern key={fileConcern.id} index={i} fileConcern={fileConcern} />
          ))}
          <Grid.Row className={css.buttonContainer}>
            <Grid.Col sm={12} md={3} />
            <Grid.Col sm={6} md={3}>
              <Button
                dataCy="approveProofApprovalBtn"
                className={css.button}
                type="button"
                color="blue"
                disabled={formikProps.values.proofApprovalFileConcernsAttributes.some(
                  elem => elem.approved === false || elem.approved === null,
                )}
                onClick={() => {
                  setApproved(true);
                  formikProps.submitForm();
                }}
              >
                Approve Proof
              </Button>
            </Grid.Col>
            <Grid.Col sm={6} md={3}>
              <Button
                dataCy="rejectProofApprovalBtn"
                className={css.button}
                type="button"
                color="blue"
                onClick={() => {
                  formikProps.validateForm().then(errors => {
                    if (Object.keys(errors).length) {
                      formikProps.submitForm();
                    } else {
                      setShowRejectModal(true);
                    }
                  });
                }}
                disabled={formikProps.values.proofApprovalFileConcernsAttributes.every(
                  elem => elem.approved === true,
                )}
              >
                Reject Proof
              </Button>
            </Grid.Col>
            <Grid.Col sm={12} md={3} />
          </Grid.Row>
        </Form>
      )}
    </Formik>
  );
};

export default ProofApprovalForm;
