import * as React from 'react';
import cn from 'classnames';
import css from './ModalFooter.scss';
import Grid from 'styleguide/components/Grid/Grid';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const ModalFooter = ({ children, ...props }: Props) => (
  <>
    <div className={css.placeholder} />
    <div className={cn(css.modalFooter, props.className)}>
      <Grid.Container className={css.container}>
        <Grid className={css.grid} noPadding>
          <Grid.Row className={css.row}>
            <Grid.Col>{children}</Grid.Col>
          </Grid.Row>
        </Grid>
      </Grid.Container>
    </div>
  </>
);

export default ModalFooter;
