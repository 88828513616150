import apiCall from 'app/libs/utils/api/apiCall';
import { ProofApprovalsResponse } from './types';
import { SharedErrors } from 'libs/utils/api/types';

// orders/:order_number/proof_approvals/
export const proofApprovalsScope = (orderNumber, path?) =>
  `/orders/${orderNumber}/proof_approvals${path || ''}`;

// orders/:order_number/proof_approvals/:proof_approval_id
export const getProofApproval = (orderNumber, proofApprovalId) => {
  const url = proofApprovalsScope(orderNumber, `/${proofApprovalId}`);
  return apiCall.get<ProofApprovalsResponse, SharedErrors>({ url });
};

// orders/:order_number/proof_approvals/:proof_approval_id
export const updateProofApproval = (orderNumber, proofApprovalId, proofApprovalAttributes) => {
  const url = proofApprovalsScope(orderNumber, `/${proofApprovalId}`);
  return apiCall.put<ProofApprovalsResponse, SharedErrors>({ url, data: proofApprovalAttributes });
};
