import * as React from 'react';
import css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import { OrderReservation } from './types';
import { contactPhone } from '../../../../constants';

export interface Props {
  orderReservation: OrderReservation;
}

const CustomerServiceContact = ({ orderReservation }: Props) => (
  <Grid.Row className={css.contactContainer}>
    <Grid.Col>
      <Span>Name: {orderReservation.csr.name}</Span>
      <br />
      <Span>Email: {orderReservation.csr.email}</Span>
      <br />
      <Span>
        Phone Number: {contactPhone} ext {orderReservation.csr.extension}
      </Span>
    </Grid.Col>
  </Grid.Row>
);

export default CustomerServiceContact;
