import { FormikErrors, FormikValues } from 'formik';
import { Status } from 'libs/utils/api/types';
import omit from 'lodash-es/omit';

export type formikStatus = {
  title: string;
  errors: Array<string | object>;
};

export const updateFormikStatus = (
  res,
  setStatus: (status?: formikStatus) => void,
  setSubmitting: (isSubmitting: boolean) => void,
  setErrors: (errors: FormikErrors<FormikValues>) => void,
) => {
  setSubmitting(false);
  if (res.status === Status.Ok) {
    setStatus(null);
  } else if (res.status === Status.ClientError) {
    setStatus({ title: res.payload.message, errors: res.payload.errors });
    setErrors(omit(res.payload.errors, ['all']));
  }
};
