import * as React from 'react';
import css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Footer from 'styleguide/components/Footer/Footer';
import { ProofApproval } from './types';
import { Status } from 'libs/utils/api/types';
import { getProofApproval } from 'api/proofApprovals';
import Approved from './Approved';
import Rejected from './Rejected';
import Expired from './Expired';
import ProofApprovalForm from './ProofApprovalForm';
import Span from 'styleguide/components/Span/Span';
import Loader from 'styleguide/components/Loader/Loader';
import { useParams } from 'react-router';

const ProofApprovalsPage = () => {
  const [proofApproval, setProofApproval] = React.useState<ProofApproval>();
  const [isErrorFetching, setIsErrorFetching] = React.useState<boolean>();
  const { orderNumber, proofApprovalId } = useParams<{ orderNumber: string; proofApprovalId: string }>();

  const getProofApprovalHandler = () => {
    getProofApproval(orderNumber, proofApprovalId).then(res => {
      if (res.status === Status.Ok) {
        setProofApproval(res.payload.proofApproval);
      } else {
        setIsErrorFetching(true);
      }
    });
  };

  React.useEffect(() => {
    getProofApprovalHandler();
  }, []);

  let form;

  if (isErrorFetching) {
    form = (
      <div className={css.loaderContainer}>
        <Span>There was an error retrieving your proof approval. Please try again later.</Span>
      </div>
    );
  } else if (!proofApproval) {
    form = (
      <div className={css.loaderContainer}>
        <Loader size="xl" />
      </div>
    );
  } else if (proofApproval.approved === true) {
    form = <Approved orderReservation={proofApproval.orderReservation} />;
  } else if (proofApproval.approved === false) {
    form = <Rejected orderReservation={proofApproval.orderReservation} />;
  } else if (proofApproval.expired === true) {
    form = <Expired orderReservation={proofApproval.orderReservation} />;
  } else {
    form = (
      <ProofApprovalForm
        proofApproval={proofApproval}
        orderNumber={orderNumber}
        setProofApprovalState={setProofApproval}
      />
    );
  }

  return (
    <>
      <Grid.Container>
        <Grid>
          <Grid.Row className={css.sectionSeparator}>
            <Grid.Col lg={2} />
            <Grid.Col lg={8}>
              <Span className={css.headerText}>Order {orderNumber}</Span>
            </Grid.Col>
            <Grid.Col lg={2} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Col lg={2} />
            <Grid.Col lg={8}>{form}</Grid.Col>
            <Grid.Col lg={2} />
          </Grid.Row>
        </Grid>
      </Grid.Container>
      <Footer />
    </>
  );
};

export default ProofApprovalsPage;
