import * as React from 'react';
import cn from 'classnames';
import css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import { products } from 'bundles/App/routes';

const NavigationButtons = () => (
  <Grid.Row className={cn(css.sectionSeparator)}>
    <Grid.Col className={cn(css.sectionColumn)}>
      <ButtonAsNativeLink buttonType="link" target="/" color="blue" className={cn(css.itemSpacing)}>
        Go Home
      </ButtonAsNativeLink>
      <ButtonAsNativeLink buttonType="link" target={products} color="blue" className={cn(css.itemSpacing)}>
        Go To Products
      </ButtonAsNativeLink>
    </Grid.Col>
  </Grid.Row>
);

export default NavigationButtons;
